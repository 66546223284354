<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">

      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-6"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-6"
            label="Url"
            v-model="formData.url"
            :validations="[
              {text: 'required!', value: $v.formData.url.$dirty && $v.formData.url.$error}
            ]">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              :disabled="$auth.disabled(method)"
              label="ใช้งาน"
              inline
              v-model="formData.isActive">
            </sgv-input-check>
          </div>
        </div>
      </div>
    </sgv-form>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  DETAIL_VIEW,
  CREATE_VIEW,
  UPDATE_VIEW,
  DESTROY_VIEW
} from './graph'

export default {
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    kioskViewType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    kioskViewId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView: `KioskView${this.$form.capitalize(this.kioskViewType)}List`,
      formData: {
        name: '',
        url: '',
        isActive: true
      },
      method: 'info'
    }
  },
  validations: {
    formData: {
      name: {required},
      url: {required},
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`kioskView:${this.kioskViewType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`kioskView:${this.kioskViewType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_VIEW(this.templateType),
        variables: {
          kioskViewType: this.kioskViewType,
          kioskViewId: this.kioskViewId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.view)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return v
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_VIEW(this.templateType),
        variables: {
          kioskViewType: this.kioskViewType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, kioskViewId: res.data.createView.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_VIEW(this.templateType),
        variables: {
          kioskViewType: this.kioskViewType,
          kioskViewId: this.kioskViewId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateView)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_VIEW(this.templateType),
        variables: {
          kioskViewType: this.kioskViewType,
          kioskViewId: this.kioskViewId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.kioskViewId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
